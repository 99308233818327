<template>
    <div class="base-textarea" >
        <div class="base-textarea__field" v-bind:style="{
            height: currentValue.includes('\n') ? 'auto' : '34px'
        }">
            <textarea
                v-model="currentValue"
                ref="textarea"
                @input="inputHandler"
                :placeholder="placeholder"
            >
            </textarea>
        </div>
        <div class="base-textarea__about" v-if="about">
            <AboutMessage :message="about" />
        </div>
    </div>
</template>

<script>
import AboutMessage from "../AboutMessage.vue";
import Form from "../../scripts/form.js"
import Field from "../../scripts/field.js"

export default {
    name: "BaseTextarea",
    components: {
        AboutMessage,
    },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        about: {
            type: String,
        },
		name: {
			type: String,
		},
		form: {
			type: Form,
		}
    },

    computed: {
        currentValue: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit("input", newValue);
            }
        }
    },

	created(){
		if (this.form && this.name){
			this.form.addField(new Field(this.name))
		}
	},


    methods: {
        inputHandler() {
            this.resize();
        },
        resize() {

            let element = this.$refs["textarea"];
            element.style.height = "32px";
            element.style.height = element.scrollHeight + "px";

            if (this.currentValue.length == 0) {
                element.style.height = "32px";
                return;
            }
        },
    },
};
</script>

<style scoped>
.base-textarea {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: fit-content;
}

.base-textarea__field{
	width: 100%;
	height: fit-content;
    border: 1px solid rgb(68, 68, 68);
    border-radius: 1px;
}

textarea {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    padding: 6px 6px 7px 6px;
    border: none;

    height: 32px;
    max-height: 70px;
    resize: none;
    overflow-y: auto;
    width: 100%;
}

textarea:focus {
    outline: none;
}
</style>