<template>
	<div class="base-checkbox">
		<div class="base-checkbox__label left" v-if="labelPlacement == 'left'">{{label}}</div>
		<div class="base-checkbox__checkbox">
			<input type="checkbox" v-model="currentValue">
		</div>
		<div class="base-checkbox__label right" v-if="labelPlacement == 'right'">{{label}}</div>
	</div>
</template>

<script>
export default {
	name: 'BaseCheckbox',

	model: {
        prop: 'value',
        event: 'update:value'
	},

	props: {
		value: {
			type: Boolean,
			required: true,
		},
		label: {
			type: String,
			default: "",
		},
		labelPlacement: {
			type: String,
			default: "right",
			validator(value) {
				// The value must match one of these strings
				return ['right', 'left'].includes(value)
			}
		},
	},

	computed: {
		currentValue: {
			get(){	
				return this.value
			},
			set(newValue){
				this.$emit('update:value', newValue)
			}
		}
	},
};
</script>

<style scoped>
.base-checkbox{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	width: inherit;
	height: 33px;
}

.base-checkbox__label{
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 20px;
	width: auto;
}

.base-checkbox__checkbox{
	height: 20px;
}

input[type="checkbox"]{
	height: 20px;
	width: 20px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}
</style>