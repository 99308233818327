var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-input",style:({
	width : _vm.expanded ? '100%' : null,
})},[_c('div',{staticClass:"base-input__field"},[_c('div',{staticClass:"field__input",class:{
			error: _vm.field.isError,
			focus: _vm.isFocused
		},style:({
			width : _vm.expanded ? '100%' : null,
		})},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],ref:"base-input",style:({
				width: _vm.expanded ? '100%' : _vm.width,
			}),attrs:{"max":_vm.limits.max,"min":_vm.limits.min,"maxlength":_vm.limits.maxlength,"inputmode":_vm.inputType=='number'?'numeric':'text',"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentValue)?_vm._i(_vm.currentValue,null)>-1:(_vm.currentValue)},on:{"input":_vm.inputHandler,"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false},"change":function($event){var $$a=_vm.currentValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],ref:"base-input",style:({
				width: _vm.expanded ? '100%' : _vm.width,
			}),attrs:{"max":_vm.limits.max,"min":_vm.limits.min,"maxlength":_vm.limits.maxlength,"inputmode":_vm.inputType=='number'?'numeric':'text',"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.currentValue,null)},on:{"input":_vm.inputHandler,"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false},"change":function($event){_vm.currentValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],ref:"base-input",style:({
				width: _vm.expanded ? '100%' : _vm.width,
			}),attrs:{"max":_vm.limits.max,"min":_vm.limits.min,"maxlength":_vm.limits.maxlength,"inputmode":_vm.inputType=='number'?'numeric':'text',"placeholder":_vm.placeholder,"type":_vm.inputType},domProps:{"value":(_vm.currentValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.currentValue=$event.target.value},_vm.inputHandler],"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false}}}),(_vm.inputType == 'number' && _vm.showNumberButtons)?_c('div',{staticClass:"field__buttons"},[_c('div',{staticClass:"field__button",on:{"click":_vm.plusHandler}},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"uil:angle-up"}})]),_c('div',{staticClass:"field__button",on:{"click":_vm.minusHandler}},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"uil:angle-down"}})])]):_vm._e()]),(_vm.about)?_c('div',{staticClass:"field-about"},[_c('AboutMessage',{attrs:{"message":_vm.about}})],1):_vm._e()]),(_vm.annotation)?_c('div',{staticClass:"base-input__annotation"},[_vm._v(" "+_vm._s(_vm.annotation)+" ")]):_vm._e(),(_vm.field.isError && _vm.showErrorMessage)?_c('div',{staticClass:"base-input__error"},[_vm._v(" "+_vm._s(_vm.field.error())+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }