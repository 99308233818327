<template>
	<div class="calendar-settings__header">
		<div class="header__left-button" 
		v-if="$slots['left-button']"
		@click="onClickLeft">
			<slot name="left-button"></slot>
		</div>
		<div class="header__title" v-bind:class="{
			'aligned' : isAlignTitle,
		}">
			{{ title }}
		</div>
		<div class="header__right-button"
		v-if="$slots['left-button']"
		@click="onClickRight">
			<slot name="right-button"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SettingsHeader',
	emits: [
		'on-click-left',
		'on-click-right',
	],
	props: {
		title: {
			type: String,
			required: true,
		},
		isAlignTitle: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onClickLeft(){
			this.$emit('on-click-left')
		},
		onClickRight(){
			this.$emit('on-click-right')
		}
	},
};
</script>

<style scoped>
.calendar-settings__header {
    height: 32px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 5px;

    background: lightgrey;
    position: fixed;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

	top: 0;
	z-index: 100;
}

.header__title{
	flex: 1;
}

.header__title.aligned{
	text-align: center;
}

.calendar-settings__header > *:not(.header__title) {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__left-button{
	height: fit-content;
}

.header__right-button{
	height: fit-content;
}
</style>