<template>
    <div class="create__calendar">

		<SettingsHeader v-if="isForm"
		title="Calendar settings"
		@on-click-left="goOut"
		@on-click-right="update">
			<template v-slot:left-button>
				<span class="iconify"
					data-icon="material-symbols:arrow-left-alt-rounded" 
                    data-width="21" 
					data-height="21">
				</span>
			</template>
			<template v-slot:right-button>
				<span class="iconify" 
					data-icon="material-symbols:check" 
					:style="'color: #0075ff'" 
					data-width="21" 
					data-height="21">
				</span>
			</template>
		</SettingsHeader>

        <div class="header" v-if="!isForm">
            <div class="header__item" @click="goOut">
                <span class="iconify" 
					data-icon="material-symbols:close-rounded" 
					:style="'color: red;'" 
					data-width="21" 
					data-height="21">
				</span>
            </div>
            <div class="header__item">
                Create New Calendar
            </div>
            <div class="header__item" @click="submit" :key="isReadyToCreate"
                v-bind:class="{disabled: !isReadyToCreate}">
                <span v-if="!isReadyToCreate" class="iconify" data-icon="material-symbols:check" 
                    :style="'color: grey'" data-width="21" data-height="21"></span>
                <span v-else class="iconify" data-icon="material-symbols:check" 
                    :style="'color: #0075ff'" data-width="21" data-height="21"></span>
            </div>
        </div>

		<div class="form">
			<div class="change__photo" v-bind:class="{
				'is-form': isForm
			}">
				<div class="photo__cont">
					<div class="photo__circle"></div>
				</div>
				<div class="change__title">Change photo</div>
			</div>
			<div class="fields__cont">

				<!-- Name -->
				<b-field 
					:type="form.uidError?
						'is-danger' : ''"
					:message="form.uidError? 
						form.uidError :
						'Will be part of URL. Only a-z, 0-9 are allowed'">
					<b-input placeholder="Calendar name"
						v-model="form.uid" type="text" required
						validation-message="Only lowercase and digits is allowed"
						pattern="[0-9a-z]*"
						@input="uidInputHandler"
						:disabled="isForm">
					</b-input>
				</b-field>

				<!-- Title -->
				<b-field
					:type="form.titleError?
						'is-danger' : ''"
					:message="form.titleError? 
						form.titleError : ''">
					<b-input placeholder="Calendar title"   
						v-model="form.title" type="text" required
						@input="() => form.titleError = null">
					</b-input>
				</b-field>

				<!-- Description -->
				<textarea class="textarea" placeholder="Calendar description"
					v-model="form.description">
				</textarea>

				<b-field>
					<b-autocomplete
						v-model="searchCountry"
						:data="search(countries, searchCountry)"
						field="name"
						placeholder="Country"
						clearable
						@blur="() => {
							if (searchCountry.length == 0)
								updateCountryId(form.countryId)
						}"
						@select="option => {
							if (option)
								updateCountryId(option.id)
						}">
						<template #empty>No results found</template>
					</b-autocomplete>
				</b-field>

				<b-field
					:type="form.timezoneError?
						'is-danger' : ''"
					:message="form.timezoneError? 
					form.timezoneError : ''">
					<b-autocomplete
						v-model="searchTimezone"
						:data="search(timezones, searchTimezone)"
						field="name"
						placeholder="Timezone"
						clearable
						@input="() => form.timezoneError = null"
						@blur="() => {
							if (searchTimezone.length == 0)
								updateTimezoneId(form.timezoneId)
						}"
						@select="option => {
							if (option)
								updateTimezoneId(option.id)
						}">
						<template #empty>No results found</template>
					</b-autocomplete>
				</b-field>

				<b-field
					:type="form.holidayError?
						'is-danger' : ''"
					:message="form.holidayError? 
						form.holidayError : ''">
					<b-select placeholder="Holiday set" expanded
						v-model="form.holidaySet" required
						@input="() => form.visibilityError = null">
						<option
							v-for="holidaySet in holidaySets"
							:value="holidaySet.id"
							:key="holidaySet.name">
							{{ holidaySet.name }}
						</option>
					</b-select>
				</b-field>

				<b-field
					:type="form.visibilityError?
						'is-danger' : ''"
					:message="form.visibilityError? 
						form.visibilityError : ''">
					<b-select placeholder="Visibility" expanded
						v-model="form.visibility" required
						@input="() => form.visibilityError = null">
						<option
							v-for="level in visibilityLevels"
							:value="level.id"
							:key="level.name">
							{{ level.name }}
						</option>
					</b-select>
				</b-field>
				
				<div class="field">
					<div class="field__content">
						<div class="checkbox-container">
							<b-checkbox v-model="form.isFullDay"></b-checkbox>
							24 working hours
						</div>
						<div class="not__fulltime">
							<TimeInput
							name="from" :form="form" :isDanger="form.timeError != null"
							v-model="form.from" :disabled="form.isFullDay"/>
							<TimeInput 
							name="to" :form="form" enableMidnight :isDanger="form.timeError != null"
							v-model="form.to"   :disabled="form.isFullDay" />                        
						</div>
					</div>
					<div class="field__error" v-if="form && form.timeError">
						{{form.timeError}}
					</div>
				</div>

				<div class="field checkbox-container">
					<b-checkbox v-model="form.isCommercial" :disabled="isForm"></b-checkbox>
					<div>Is Pro</div>
				</div>

				<SocialNetworkLinksSelector v-model="form.socialNetworkLinks"/>
			</div>
		</div>
    </div>
</template>

<script>
import visibilityLevel from '../scripts/visibilityLevels.js'
import DropButton from '../components/inputs/DropButton.vue';
import AboutMessage from '../components/AboutMessage.vue';
import Field from '../components/inputs/Field.vue';
import CalendarCreationForm from '../components/forms/templates/create-calendar'
import router from '../router'; 
import CMS from '../service/cms/service';
import SocialNetworkLinksSelector from '../components/inputs/SocialNetworkLinksSelector.vue';
import TimeInput from '../components/inputs/TimeInput.vue';
import BaseTextarea from '../components/inputs/BaseTextarea.vue'
import BaseCheckbox from '../components/inputs/BaseCheckbox.vue';
import BaseInput from '../components/inputs/BaseInput.vue'
import CreateCalendarForm from '../components/forms/templates/create-calendar';
import { ToastProgrammatic as Toast } from 'buefy'
import SettingsHeader from '../components/settings/SettingsHeader.vue';

export default {
    name: "CreateCalendar",
    components:{
        DropButton,
        BaseTextarea,
        BaseCheckbox,
        BaseInput,
        AboutMessage,
        Field,
		SettingsHeader,
        TimeInput,
        SocialNetworkLinksSelector,
    },
    props:{
        isForm: {
            type: Boolean,
            default: false
        },
        calendarSettings: {
            type: Object
        },
    },
    computed: {

        userCountry(){
            let countryId = this.$store.getters.userCountryId
            return countryId != null ? countryId : null 
        },
        userTimezone(){
            let timezoneId = this.$store.getters.userTimezoneId
            return timezoneId != null ? timezoneId : null
        },
        countries(){
            let countries = this.$store.getters.countries
         
            let currentCountry = countries.find((country) => {
                return country.id == this.form.countryId
            })
            if (currentCountry)
                this.searchCountry = currentCountry.name
            return countries
        },
        timezones(){
            let timezones = this.$store.getters.timezones 
                     
            let currentTimezone = timezones.find((timezone) => {
                return timezone.id == this.form.timezoneId
            })
            if (currentTimezone)
                this.searchTimezone = currentTimezone.name
            return timezones
        },
        visibilityLevels(){
            return visibilityLevel
        }, 
        isReadyToCreate(){
            let disableErrorMessage = false
            return this.form.validate(disableErrorMessage)
        }
    },
    data() {
        return {
            form: new CalendarCreationForm(),
            fromTimeArchive: "",
            fromIsErrorArchive: false,
            toTimeArchive: "",
            toIsErrorArchive: false,
            holidaySets: [],
            searchTimezone: "",
            searchCountry: "",
            isFormSubmitted: false,
        }
    },
    watch: {
        calendarSettings(newValue) {
            if (newValue && this.isForm) {
                this.form = this.form.fill(this.calendarSettings)
                this.updateHolidaySet(this.form.countryId)
            }
        },
        userCountry(newValue) {
            if (!this.form.countryId && newValue) {
                this.updateCountryId(newValue)
            }
        },
        userTimezone(newValue) {
            if (!this.form.timezoneId && newValue) {
                this.updateTimezoneId(newValue)
            }
        },
        'form.from': {
            handler(newValue) {
                this.form.timeError = null
            },
            deep: true,
        },
        'form.to': {
            handler(newValue) {
                this.form.timeError = null
            },
            deep: true,
        },
        'form.isFullDay': {
            handler(newValue, oldValue) {
				if (newValue == oldValue)
					return 

                if (newValue == true) {
                    this.fromTimeArchive = this.form.from
                    this.toTimeArchive = this.form.to

                    this.fromIsErrorArchive = this.form.fields["from"].isError
                    this.form.fields["from"].isError = false

                    this.toIsErrorArchive = this.form.fields["to"].isError
                    this.form.fields["to"].isError = false

                    this.form.from = "00:00"
                    this.form.to = "24:00"
                } 
                else {
                    this.form.from = this.fromTimeArchive
                    this.form.to = this.toTimeArchive
                    if (this.form.fields["from"])
                        this.form.fields["from"].isError = this.fromIsErrorArchive
                    if (this.form.fields["to"])
                        this.form.fields["to"].isError = this.toIsErrorArchive
                }
            },
            deep: true,
        }
    },
    created(){  

		if (this.isForm && this.calendarSettings && this.calendarSettings.uid) {
            this.form = this.form.fill(this.calendarSettings)
            this.updateHolidaySet(this.form.countryId)
        }

        if (!this.isForm && !this.calendarSettings) {
            if (!this.form.countryId && this.userCountry != -1) {
                this.updateCountryId(this.userCountry)
            }
            if (!this.form.timezoneId && this.userTimezone != -1) {
				this.updateTimezoneId(this.userTimezone)
            }
        } 
        // TODO : Setup validation of fields        
    },
    methods: {
		goOut(){
			if (window.history.length > 2) {
				this.$router.back()
			} else if (!this.isForm) {
				this.$router.push({
					name: 'home',
				})
			} else {
				this.$router.push({
					name: 'calendar-settings-menu',
					params: {
						calendarUid: this.calendarSettings.uid
					}
				})
			}
		},

		update(){
			let isValid = this.form.validate()
			if (!isValid || this.isFormSubmitted) 
				return

			this.isFormSubmitted = true
			const updatedCalendarData = this.form.toSubmitData()
			const calendarUid = this.calendarSettings.uid
			delete updatedCalendarData.uid
			CMS.calendar.update(calendarUid, updatedCalendarData).then((newCalendar) => {
				if (newCalendar.err){
					throw newCalendar.err
				}

				updatedCalendarData.timezone = this.$store.getters.getTimezoneByID(this.form.timezoneId)
				const currentCalendar = this.$store.getters.currentCalendar()
				if (!currentCalendar)
					return

				currentCalendar.update(updatedCalendarData)

				Toast.open({
					message: 'Calendar updated successfully!',
					type: 'is-success',
					position: 'is-bottom',
					duration: 5000,
				})
			})
			.catch((err) => {console.error(err)})
			.finally(() => {
				this.isFormSubmitted = false
			})
		},

        submit(){
            let isValid = this.form.validate()
            if (!isValid || this.isFormSubmitted) 
                return

            this.isFormSubmitted = true
            let newCalendarData = this.form.toSubmitData()
            CMS.calendar.create(newCalendarData).then( newCalendar => {
                if (newCalendar.err){
                    throw newCalendar.err
                }
                
                this.form = new CreateCalendarForm()
				router.push({
					name: "calendar-settings-menu", 
					params: {
						calendarUid: newCalendar.uid, 
						isNewCalendar: true
					}
				})
                // let uid = newCalendar.uid
                // CMS.calendar.getByUid(uid).then( calendarData => {
                //     if (calendarData.err){
                //         throw calendarData.err
                //     }

				// 	calendarData = calendarData["allowed"]
				// 	if (!calendarData)
				// 		return ;

                //     // Add calendar data to future view (settings or slot table)
                //     this.$store.dispatch('set-last-calendar', calendarData)
                    
                //     // Add calendar data to user managed calendars (for personal calendar list)
                //     this.$store.dispatch('add-managed-calendar', {uid, calendar: calendarData})
                    
                //     this.$store.dispatch("set-calendar", {
                //         uid: uid,
                //         calendar: calendarData,
                //     })
                //     this.$store.dispatch("setup-slot-templates")
				// 	this.setupWorkdayCalendar(calendarData.workdayCalendarId)

                //     // Setup work hours for the calendar
                //     this.setupWorkHours({
                //         start: calendarData.from,
                //         end: calendarData.to,
                //     })
                    
                // })
            }).catch(err => {
                if (err.response && err.response.status == 409) {
                    this.form.calendarWithThisUidExist()
                } else {
					console.error(err)
				}
            })
            .finally(() => {
                this.isFormSubmitted = false
            })
        },

        nameValidator(name){
            return name.toLowerCase().replace(/[^a-z0-9-_]/g, "")
        },  

        updateCountryId(newValue){
            this.form.countryId = newValue
            this.form.countryIndex = newValue - 1
            if (!newValue) return 

            let currentCountry = this.countries.find((country) => {
                return country.id == newValue
            })
            if (currentCountry)
                this.searchCountry = currentCountry.name
            this.updateHolidaySet(this.form.countryId)
        },
        updateCountryIndex(newValue){

            if (!newValue) {
                this.form.countryIndex = null
                this.form.countryId = null
                return
            }

            this.form.countryIndex = newValue
            this.form.countryId = newValue + 1

            if (!newValue) return 

            this.updateHolidaySet(this.form.countryId)
        },

        updateTimezoneId(newValue) {
            this.form.timezoneId = newValue
            this.form.timezoneIndex = newValue - 1
            let currentTimezone = this.timezones.find((timezone) => {
                return timezone.id == newValue
            })
            if (currentTimezone)
                this.searchTimezone = currentTimezone.name
        },

        updateTimezoneIndex(newValue) {
            if (!newValue) {
                this.form.timezoneIndex = null
                this.form.timezoneId = null
                return
            }

            this.form.timezoneIndex = newValue
            this.form.timezoneId = newValue + 1
        },

        updateHolidaySetIndex(newValue) {
            this.holidaySetIndex = newValue
            this.holidaySet = newValue != null ?
                this.holidaySets[newValue].id
                :null
        },
        // updateHolidaySet(newValue) {
        //     this.form.
        // }

        updateHolidaySet(countryId){
            return CMS.geo.workdayCalendar.getByCountry(countryId).then(holidaySets => {
                if (holidaySets.err){
                    throw holidaySets.err
                }

                this.holidaySets = holidaySets ?? []
                if (holidaySets && holidaySets.length > 0){
                    let holidaySet = holidaySets[0]

                    this.form.holidaySet = holidaySet.id
                    this.form.holidaySetIndex = 0
                }
            })
        },
        search(items, searchString){
            if (!searchString || searchString.length == 0)
                return items
            
            let search = searchString.toLowerCase()

            return items.filter(item => {
                if (item.title)
                    return item.title.toLowerCase().includes(search)
                else if (item.name)
                    return item.name.toLowerCase().includes(search)
                else if (item.Name)
                    return item.Name.toLowerCase().includes(search)
            }
            )
        },
        setupWorkdayCalendar(workdayCalendarId){
			CMS.geo.workdayCalendar
				.getByID(workdayCalendarId)
				.then((workdayCalendar) => {
					if (workdayCalendar.err) 
                        throw workdayCalendar.err

					let holidays = this.setupHolidays(workdayCalendar.weekends)
					let special = this.setupSpecialDays(workdayCalendar.specialDays)
					this.$store.dispatch("update-work-date", {
						holidays: holidays,
						special: special,
					});
				})
		},
		setupWorkHours(work){
            let start = {
                hour: Math.floor(work.start / 60),
                minute: work.start - Math.floor(work.start / 60) * 60
            }
            let end = {
                hour: Math.floor(work.end / 60),
                minute: work.end  - Math.floor(work.end / 60) * 60
            }

            this.$store.dispatch('update-work-time', {start, end})
        },

        setupSpecialDays(specialDays) {
            let special = specialDays || [];
            return special.map((day) => {
                if (day["dt"] !== undefined) {
                    let date = new Date(day["dt"]);
                    return {
                        m: date.getMonth(),
                        d: date.getDate(),
                        typeId: day.typeId,
                    };
                }

                return { ...day, m: day.m - 1 };
            });
        },

        setupHolidays(holidays) {
            return holidays.map((dayIndex) => {
                if (dayIndex == 7) return 0;
                return dayIndex;
            });
        },

		uidInputHandler(newValue) {
			this.form.uidError = null
			this.form.uid = newValue.toLowerCase()
		}
    },
}
</script>

<style scoped> 
.description{
    min-height: 4em;
}
.textarea:not([rows]){
    min-height: 5em;
    margin-bottom: 0.75rem;
    max-height: 9em;
}
.create__calendar{
    display: flex;
	padding: 24px 16px;
    flex-direction: column;
    animation: fade .3s;
    align-items: center;
	margin-top: 32px;
}

.form{
	display: flex;
	flex-direction: column;
	gap: 16px;
	width:100%;
}

.change__photo{
	margin-top: 0px;
}

.change__photo.is-form{
	margin-top: 0;
}

.header{
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    background: lightgrey;
    width: 100%;
	height: 32px;
    z-index: 2;

	display:flex;
	justify-content: space-between;

    position: fixed;
    top: 0;
}
.header__item{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: background .3s;
}

.submit-button-container{
	position: fixed;
	display: flex;
	justify-content: center;
	top: 5px;
	right: 5px;
	height: 21px;
	width: 21px;
	z-index: 10;
}

.header__item.disabled{
    background: #c6c6c6;
}

.photo__cont{
    display: flex;
    justify-content: center;
}
.photo__circle{
    height: 120px;
    width: 120px;

    border: 1px solid black;
    border-radius: 75px;
}

.change__title{
    display: flex;
    margin-top: 10px;
    text-decoration: underline;
    justify-content: center;
    color:#0075ff;
}



.fields__cont{
    min-width: 320px;
    width: 100%;

    display: flex;
    flex-direction: column;
}


input.disabled{
    background: #e9e9e9;
    color: grey;
}

.checkbox{
    width: 20px;
    height: 20px;
}

.checkbox-container{
    display: flex;
    align-items: center;
    height: 100%;
}

.checkbox-container > * {
    display: flex;
    align-items: center;
}

.not__fulltime{
    display: flex;
    gap: 10px;
}

.not__fulltime > input{
    width: 41px;
}

.field__content{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    height: 40px;
}

.field__error{
    color: red;
    font-size: 11px;
}

.right {
    text-align: end;
}

</style>