const PRIVATE = 1
const PUBLIC = 2
const PUBLIC_TO_REGISTERED = 3
const PUBLIC_TO_FOLLOWERS = 4


const visibilityLevel = [
    {id: 1, name: 'Private'},
    {id: 2, name: 'Visible to everyone'},
    {id: 3, name: 'Visible to all registered users'},
    {id: 4, name: 'Visible to followers only'},
]

export { PRIVATE, PUBLIC, PUBLIC_TO_REGISTERED, PUBLIC_TO_FOLLOWERS }

export default visibilityLevel