<template>
	<div class="social-links-input">

		<div class="social-links__all" v-if="activeSocialNetworkLinks.length != allSocialNetworks.length">
			<div class="all-links__title">Click to add :</div>
			<div class="all-links__items">
				<div class="link" v-for="network in allSocialNetworks" 
					:key="`link__${network.id}`"
					v-show="!network.selected"
					@click="network.disabled?null:addLink(network.type)">
					<span class="iconify" :data-icon="network.icon" data-width="22" data-height="22"></span>
				</div>
			</div>
		</div>
	    <div class="social-links__active" v-if="activeSocialNetworkLinks.length > 0">
			<div class="active-link" v-for="link in activeSocialNetworkLinks" :key="`active_link_${link.type}`">
			
				<div class="active-link__icon">
					<span class="iconify" :data-icon="link.icon" data-width="22" data-height="22"></span>
				</div>

				<div>
					<BaseInput v-model="link.value" :placeholder="`${link.type} link`" expanded/>
				</div>

				<div class="active-link__remove-button" @click="removeLink(link.type)">
					<span class="iconify" data-icon="uil:multiply"  data-width="16" data-height="16"></span>
				</div>
			</div>
        </div>
	</div>
</template>

<script>
import BaseInput from './BaseInput.vue';

export default {
	name: 'SocialNetworkLinksSelector',

	components: {
		BaseInput,
	},

	props: {
		value: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
            allSocialNetworks: [
                {id: 1, type : "facebook", 	json: "fb", 	icon : "uil:facebook-f", selected: false},
                {id: 2, type : "twitter",  	json: "twt", 	icon : "uil:twitter-alt", selected: false},
                {id: 3,	type : "instagram",	json: "ins", 	icon : "uil:instagram", selected: false},
                {id: 4, type : "location", 	json: "loc",	icon : "uil:map-marker", selected: false, disabled: true},
                {id: 5,	type : "site", 		json: "web", 	icon:  "carbon:earth-filled", selected: false, disabled: true},
                {id: 6,	type : "email",		json: "ema",	icon: "uil:home", selected: false, disabled:true},  
            ],
		};
	},

	watch: {
		value(){
			this.allSocialNetworks = this.allSocialNetworks.map(sn => {
				let isActive = this.value.find(v => v.type == sn.type)
				if (isActive) 
					sn.selected = true
				return sn
			})
		}	
	},

	created() {
		this.allSocialNetworks = this.allSocialNetworks.map(sn => {
			let isActive = this.value.find(v => v.type == sn.type)
			if (isActive) 
				sn.selected = true
			return sn
		})
	},

	computed : {
		activeSocialNetworkLinks: {
			get(){
				return this.value
			},
			set(newValue){
				this.$emit('input', newValue);
			}
		}
	},

	methods: {
		addLink(linkType){
            let socialNetwork = this.allSocialNetworks.find(sn => sn.type == linkType)
            if (!socialNetwork) return
            let socialNetworkIndex = this.allSocialNetworks.indexOf(socialNetwork)

            let newActiveSocialNetwork = {
				id: socialNetwork.id,
                type: socialNetwork.type,
                json: socialNetwork.json,
                icon: socialNetwork.icon,
                value: "",
            }

            this.$set(this.allSocialNetworks[socialNetworkIndex], "selected", true)

			let allActiveLinks = [...this.activeSocialNetworkLinks]

			allActiveLinks.push(newActiveSocialNetwork)
			this.activeSocialNetworkLinks = allActiveLinks
		},
		removeLink(linkType){
			let socialNetworkLinkIsActive = this.activeSocialNetworkLinks.find(sn => sn.type == linkType)
			if (!socialNetworkLinkIsActive) return 

			let socialNetworkIndex = this.allSocialNetworks.findIndex(sn => sn.type == linkType)
			this.$set(this.allSocialNetworks[socialNetworkIndex], "selected", false)

			let allActiveLinks = [...this.activeSocialNetworkLinks].filter(sn => sn.type != linkType)
			this.activeSocialNetworkLinks = allActiveLinks
		},
	},
};
</script>

<style scoped>
.social-links-input{
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.social-links__all{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.all-links__items{
	display: flex;
	gap: 10px;
}

.link{
	color: grey;
	border: 1px solid grey;
	border-radius: 4px;
	background: #e9e9e9;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 28px;
	width: 28px;
}

.social-links__active{
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.active-link{
	display: grid;
	grid-template-columns: 22px calc(100% - 22px - 10px - 38px - 10px) 38px;
	column-gap: 10px;
	width: 100%;
}

.active-link > * {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.active-link__icon{
	color: #0075ff;
}

.active-link__value{
	width: 100%;
}

.active-link__remove-button{
	color: red;
}

</style>